import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  
  return (
    <Layout>
      <SEO title="Home" />
      <section className="safety-tips-banner background-overlay">
        <h1 className="banner-title">Safety Tips</h1>
      </section>

      <section className="privacy-policy-content">
        <p>
          Yitnow is an app which facilitates the meeting of people nearby, and giving them a public place to do so. Meeting new people is exciting, but you should always be cautious when interacting with someone you don’t know. Use your best judgment and put your safety first, whether you are exchanging initial messages or meeting in person. While you can’t control the actions of others, there are things you can do to help you stay safe during your Yitnow experience.
    </p>
        <div className="divider"></div>
        <h2>Never Send Money or Share Financial Information </h2>
        <p>
          Never send money, especially over wire transfer, even if the person claims to be in an emergency. Never share information that could be used to access your financial accounts. If another user asks you for money, report it to us immediately.
    </p>
        <div className="divider"></div>
        <h2>Protect Your Personal Information</h2>
        <p>
          Never share personal information, such as your social security number, home or work address, or details about your daily routine (e.g., that you go to a certain gym every Monday) with people you don’t know. If you are a parent, limit the information that you share about your children on your profile and in early communications. Avoid sharing details such as your children’s names, where they go to school, or their ages or genders.
    </p>
        <div className="divider"></div>
        <h2>Protect Your Account</h2>
        <p>
          Be sure to pick a strong password, and always be careful when logging into your account from a public or shared computer. Yitnow will never send you an email asking for your password information — if you receive an email asking for account information, report it immediately.
    </p>
        <div className="divider"></div>
        <h2>Always meet in Public and Stay in Public</h2>
        <p>
          Meet in a populated, public place — never at your home, another persons home, or any other private location. If a person you have met on Yitnow pressures you to go to a private location, leave the situation. <strong>Utilise the tag feature on Yitnow to find public places nearby to meet people</strong>.
    </p>
        <div className="divider"></div>
        <h2>Tell Friends and Family About Your Plans</h2>
        <p>
          Tell a friend or family member of your plans, including when and where you’re going. Have your cell phone charged and with you at all times.
    </p>
        <div className="divider"></div>
        <h2>Be in Control of Your Transportation</h2>
        <p>
          Be in control of how you get to and from meeting people irl that you’ve spoken to on Yitnow so that you can leave whenever you want. If you’re driving yourself, it’s a good idea to have a backup plan such as a ride-share app or a friend to pick you up.
    </p>
        <div className="divider"></div>
        <h2>Know Your Limits</h2>
        <p>
          Be aware of the effects of drugs or alcohol on you specifically - they can impair your judgment and your alertness. If your date tries to pressure you to use drugs or drink more than you’re comfortable with, hold your ground and end the date.
    </p>
        <div className="divider"></div>
        <h2>Dont Leave Drinks or Personal Items Unattended</h2>
        <p>
          Know where your drink comes from and know where it is at all times — only accept drinks poured or served directly from the bartender or server. Many substances that are slipped into drinks to facilitate sexual assault are odorless, colorless, and tasteless. Also, keep your phone, purse, wallet, and anything containing personal information on you at all times.
    </p>
        <div className="divider"></div>
        <h2>If You Feel Uncomfortable, Leave</h2>
        <p>
          If you feel uncomfortable around someone you have met on Yitnow then exit the situation.
    </p>
      </section>
    </Layout>
  )
};

